import { FC, useEffect } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import InputField from "../components/shared/InputField";
import { useHandelCountry } from "../hooks/location/useHandelCountry";
import { Country } from "../model/Country";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { handelInputMap } from "../util/handelInputMap";
import AHLogo from "../components/icons/AHLogo";
type Props = {
  defaultValue?: Country;
  open: boolean;
  onClose: (state: boolean) => void;
  intl: IntlShape;
};

const CountryModal: FC<Props> = ({ open, onClose, defaultValue, intl }) => {
  const { onSubmit, onUpdate, errors, register, handleSubmit, setValue } =
    useHandelCountry(onClose);

  useEffect(() => {
    if (defaultValue) {
      setValue("country_fa", defaultValue.country_fa);
      setValue("country_ps", defaultValue.country_ps);
      setValue("country_en", defaultValue.country_en);
      setValue("countryCode", defaultValue.countryCode);
    } else {
      setValue("country_fa", "");
      setValue("country_ps", "");
      setValue("country_en", "");
      setValue("countryCode", "");
    }
  }, [defaultValue]);

  const onSubmitState = (data: any) => {
    if (defaultValue) {
      onUpdate(data, Number(defaultValue.id));
    } else {
      onSubmit(data);
    }
  };
  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center">
          <AHLogo className="h-16 text-primary-600 mx-auto" type="primary" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.ADD_NEW_COUNTRY.id}
              defaultMessage={TRANSLATION.ADD_NEW_COUNTRY.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.ENTER_COUNTRY_INFORMATION.id}
              defaultMessage={
                TRANSLATION.ENTER_CATEGORY_INFORMATION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <div>
          <form
            id="login"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmitState)}
          >
            <div className="w-full  mt-4">
              <InputField
                name="countryCode"
                label={handelInputMap(intl, "country_code")}
                placeholder={handelInputMap(intl, "country_code_placeholder")}
                register={register}
                errors={errors?.countryCode}
                required={true}
              />
            </div>
            <div className="w-full mt-4">
              <InputField
                name="country_fa"
                label={handelInputMap(intl, "country_name_fa")}
                placeholder={handelInputMap(intl, "country_name_placeholder")}
                register={register}
                errors={errors?.country_fa}
                required={true}
              />
            </div>
            <div className="w-full  mt-4">
              <InputField
                name="country_ps"
                label={handelInputMap(intl, "country_name_ps")}
                placeholder={handelInputMap(intl, "country_name_placeholder")}
                register={register}
                errors={errors?.country_ps}
                required={true}
              />
            </div>
            <div className="w-full  mt-4">
              <InputField
                name="country_en"
                label={handelInputMap(intl, "country_name_en")}
                placeholder={handelInputMap(intl, "country_name_placeholder")}
                register={register}
                errors={errors?.country_en}
                required={true}
              />
            </div>
            <div className=" flex flex-col sm:flex-row gap-4 mt-10 pb-4 ">
              <div className="flex w-full">
                <Button
                  size="medium"
                  onClick={() => onClose(false)}
                  theme="error"
                  className="w-full"
                >
                  <Typo.heading4 fontWeight="font-bold" className="">
                    <FormattedMessage
                      id={TRANSLATION.CANCEL.id}
                      defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
              <div className="flex w-full">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="w-full"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    <FormattedMessage
                      id={TRANSLATION.ADD.id}
                      defaultMessage={TRANSLATION.ADD.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CountryModal;
