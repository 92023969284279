import { FC, useEffect, useState } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { useHandelProvince } from "../hooks/location/useHandelProvince";
import { Typo } from "../components/shared/typo";
import { Province } from "../model/Province";
import Dropdown from "../components/shared/Dropdown";
import { useHandelCountry } from "../hooks/location/useHandelCountry";
import { DropdownOptionModel } from "../model/DropdownOption";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { handelInputMap } from "../util/handelInputMap";
import InputField from "../components/shared/InputField";
import {
  getLocaleCategories,
  getLocaleCountries,
  getLocalTitle,
} from "../util/appUtil";
import AHLogo from "../components/icons/AHLogo";
type Props = {
  defaultValue?: Province;
  open: boolean;
  onClose: (state: boolean) => void;
  intl: IntlShape;
  provinces: DropdownOptionModel[];
  locale: string;
};

const ProvinceModal: FC<Props> = ({
  open,
  onClose,
  defaultValue,
  intl,
  locale,
}) => {
  const {
    onSubmit,
    onUpdate,
    handleSubmit,
    register,
    errors,
    reset,
    setValue,
  } = useHandelProvince(onClose);
  const { ToGetCounntries } = useHandelCountry();
  const countries = ToGetCounntries();
  const [country, setCountry] = useState<DropdownOptionModel>();

  useEffect(() => {
    if (countries?.data && defaultValue) {
      const city = countries?.data.find(
        (obj) => obj.id === defaultValue.countryId
      );
      if (city)
        setCountry({
          id: city.id,
          title: getLocalTitle(
            locale,
            city.country_fa,
            city.country_ps,
            city.country_en
          ),
        });
      setValue("state_fa", defaultValue.state_fa);
      setValue("state_ps", defaultValue.state_ps);
      setValue("state_en", defaultValue.state_en);
    } else if (
      countries?.data &&
      countries?.data?.length > 0 &&
      !defaultValue
    ) {
      setCountry({
        id: countries?.data[0].id,
        title: getLocalTitle(
          locale,
          countries?.data[0].country_fa,
          countries?.data[0].country_ps,
          countries?.data[0].country_en
        ),
      });
      setValue("state_fa", "");
      setValue("state_ps", "");
      setValue("state_en", "");
    }
  }, [countries?.data, defaultValue]);

  const onSubmitProvince = (data: any) => {
    if (defaultValue) {
      onUpdate(Number(country?.id), data, Number(defaultValue.id));
    } else {
      onSubmit(Number(country?.id), data);
      reset();
    }
  };

  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center">
        <AHLogo className="h-16 text-primary-600 mx-auto" type="primary" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.ADD_NEW_PROVINCE.id}
              defaultMessage={TRANSLATION.ADD_NEW_PROVINCE.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.ENTER_PROVINCE_INFORMATION.id}
              defaultMessage={
                TRANSLATION.ENTER_PROVINCE_INFORMATION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmitProvince)}
        >
          <div className="w-full  mt-4">
            <Dropdown
              options={getLocaleCountries(locale, countries?.data)}
              defaultValue={country}
              onChange={(item: DropdownOptionModel) => {
                setCountry(item);
              }}
              placeholder={handelInputMap(intl, "country_placeholder")}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="state_fa"
              label={handelInputMap(intl, "state_fa")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.state_fa}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="state_ps"
              label={handelInputMap(intl, "state_ps")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.state_ps}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="state_en"
              label={handelInputMap(intl, "state_en")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.state_en}
              required={true}
            />
          </div>
          <div className="flex flex-row gap-x-2 pt-6">
            <Button
              onClick={() => onClose(false)}
              size="medium"
              theme="error"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button
              size="medium"
              type="submit"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.ADD.id}
                  defaultMessage={TRANSLATION.ADD.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ProvinceModal;
