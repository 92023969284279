import { FC, useState } from "react";
import Modal from "../wrapper/modal";
import InputField from "../components/shared/InputField";
import Button from "../components/shared/button";
import { useHandelRegister } from "../hooks/user/useHandelRegister";
import { Typo } from "../components/shared/typo";
import Dropdown from "../components/shared/Dropdown";
import { DropdownOptionModel } from "../model/DropdownOption";
import { getRoles } from "../static/constants";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { handelInputMap } from "../util/handelInputMap";
import AHLogo from "../components/icons/AHLogo";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  intl: IntlShape;
  provinces: DropdownOptionModel[];
};

const UserModal: FC<Props> = ({ open, onClose, intl,provinces }) => {
  const roles = getRoles(intl);
  const [province, setProvince] = useState<DropdownOptionModel>();
  const [role, setRole] = useState<DropdownOptionModel>(roles[0]);
  const { register, errors, handleSubmit, onSubmit, loading } =
    useHandelRegister();
  const onRegister = async (data: any) => {
    try {
      await onSubmit(data, Number(province?.id), Number(role?.id));
    } catch (error) {}
  };

  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center mb-5">
        <AHLogo className="h-16 text-primary-600 mx-auto" type="primary" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.CREATE_ACCOUNT.id}
              defaultMessage={TRANSLATION.CREATE_ACCOUNT.defaultMessage}
            />
          </Typo.heading1>
        </div>
        <form
          id="signup"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onRegister)}
        >
          <div className="space-y-5">
            <div className="w-full">
              <InputField
                name="name"
                placeholder={handelInputMap(intl, "name_placeholder")}
                type="text"
                register={register}
                errors={errors?.name}
                required={true}
              />
            </div>
            <div className="w-full">
              <InputField
                name="email"
                placeholder={handelInputMap(intl, "email_placeholder")}
                type="email"
                register={register}
                errors={errors?.email}
                required={true}
              />
            </div>
            <div className="w-full">
              <InputField
                name="phone"
                type="text"
                placeholder={handelInputMap(intl, "phone_placeholder")}
                register={register}
                errors={errors?.phone}
                required={true}
              />
            </div>
            <div className="w-full">
              <InputField
                name="password"
                placeholder={handelInputMap(intl, "password_placeholder")}
                type="password"
                register={register}
                errors={errors?.password}
                required={true}
              />
            </div>
            <div className="w-full">
              <Dropdown
                options={provinces || []}
                defaultValue={province}
                onChange={setProvince}
                placeholder={handelInputMap(intl, "province_placeholder")}
              />
            </div>
            <div className="w-full">
              <Dropdown
                options={roles}
                defaultValue={role}
                onChange={setRole}
                placeholder={handelInputMap(intl, "provilege_placeholder")}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-4 mt-5">
            <Button
              loading={loading}
              size="medium"
              type="submit"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.REGISTER.id}
                  defaultMessage={TRANSLATION.REGISTER.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button className="w-full" theme="secandory">
              <Typo.heading4 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserModal;
