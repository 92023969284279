import { FC } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { useHandelLogin } from "../hooks/login/useHandelLogin";
import Divider from "../components/shared/Divider";
import FacebookCircleIcon from "../components/icons/FacebookCircleIcon";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../lib/reducers/modal.slice";
import { handelInputMap } from "../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../static/translation";
import Alert from "../components/shared/Alert";
import InputField from "../components/shared/InputField";
import GoogleIcon from "../components/icons/GoogleIcon";
import AHLogo from "../components/icons/AHLogo";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
};

const Login: FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onGoogleLogin,
    onFacebookLogin,
    loading,
    alert,
    setAlert,
  } = useHandelLogin();

  return (
    <Modal className="max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center">
          <AHLogo className="h-16 text-primary-600 mx-auto" type="primary" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.LOGIN_TITLE.id}
              defaultMessage={TRANSLATION.LOGIN_TITLE.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.LOGIN_DESCRIPTION.id}
              defaultMessage={TRANSLATION.LOGIN_DESCRIPTION.defaultMessage}
            />
          </Typo.heading5>
          <Alert
            type={alert?.type}
            title={alert?.title}
            description={alert?.description}
            open={alert?.open || false}
            setOpen={() =>
              setAlert((prevAlert: any) => ({
                ...prevAlert,
                open: false,
              }))
            }
          />
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full mt-3 mb-7">
            <InputField
              name="email"
              label={handelInputMap(intl, "email")}
              placeholder={handelInputMap(intl, "email_placeholder")}
              type="email"
              register={register}
              errors={errors?.email}
              required={true}
            />
          </div>
          <div className="w-full my-4">
            <InputField
              name="password"
              label={handelInputMap(intl, "password")}
              type="password"
              placeholder={handelInputMap(intl, "password_placeholder")}
              register={register}
              errors={errors?.password}
              required={true}
            />
          </div>
          <div className="flex justify-start w-full items-center mt-2">
            <Button
              onClick={() => {
                dispatch(hideModal("login"));
                dispatch(showModal("forgotPassword"));
              }}
              size="sxtra-small"
              theme="label"
            >
              <Typo.heading4
                fontWeight="font-bold"
                className="text-primary-600"
              >
                <FormattedMessage
                  id={TRANSLATION.FORGOT_YOUR_PASSWORD.id}
                  defaultMessage={
                    TRANSLATION.FORGOT_YOUR_PASSWORD.defaultMessage
                  }
                />
              </Typo.heading4>
            </Button>
          </div>
          <div className=" flex flex-col space-y-2 pt-6">
            <Button
              loading={loading}
              size="medium"
              type="submit"
              theme="primary"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.LOGIN.id}
                  defaultMessage={TRANSLATION.LOGIN.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <div className="relative justify-center py-6">
              <Divider type="horizontal"></Divider>
              <div className=" text-gray-400 text-sm font-medium px-2 font-iransans bg-white absolute top-4 w-28 left-0 right-0 mx-auto ">
                <FormattedMessage
                  id={TRANSLATION.CONTINUE_WITH.id}
                  defaultMessage={TRANSLATION.CONTINUE_WITH.defaultMessage}
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <Button
                theme="stroke"
                icon={<GoogleIcon className="h-6" />}
                onClick={onGoogleLogin}
              >
                <FormattedMessage
                  id={TRANSLATION.LOGITN_WITH_GOOGLE.id}
                  defaultMessage={TRANSLATION.LOGITN_WITH_GOOGLE.defaultMessage}
                />
              </Button>
              <Button
                theme="stroke"
                icon={<FacebookCircleIcon className="h-6" />}
                onClick={onFacebookLogin}
              >
                <FormattedMessage
                  id={TRANSLATION.LOGITN_WITH_FACEBOOK.id}
                  defaultMessage={
                    TRANSLATION.LOGITN_WITH_FACEBOOK.defaultMessage
                  }
                />
              </Button>
            </div>
            <div className="py-2 flex items-center justify-center">
              <Button className="" theme="label">
                <Typo.heading4 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.YOU_DONOT_HAVE_ACCOUNT.id}
                    defaultMessage={
                      TRANSLATION.YOU_DONOT_HAVE_ACCOUNT.defaultMessage
                    }
                  />
                  <span
                    onClick={() => {
                      dispatch(hideModal("login"));
                      dispatch(showModal("signup"));
                    }}
                    className=" text-primary-700"
                  >
                    <FormattedMessage
                      id={TRANSLATION.REGISTER.id}
                      defaultMessage={TRANSLATION.REGISTER.defaultMessage}
                    />
                  </span>
                </Typo.heading4>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
