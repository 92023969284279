import { FormattedMessage } from "react-intl";
import { Typo } from "../../../components/shared/typo";
import { CreateUserProp, User } from "../../../model/User";
import { TRANSLATION } from "../../../static/translation";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { useIntl } from "react-intl";
import { getLocaleProvinces } from "../../../util/appUtil";
import TickMark from "../../../assets/image/tick-mark.svg";
import Image from "../../../components/shared/Image";
import { ROUTES } from "../../../constants/routes";
import Button from "../../../components/shared/button";
import PlusIcon from "../../../components/icons/PlusIcon";
type Props = {
  className?: string;
  disabled?: boolean;
  user: User | null;
  role: number;
  setRole: (role: number) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  onPrev: () => void;
  onUpdateProfile: (user: CreateUserProp) => Promise<void>;
  loading: boolean;
  locale: string;
};

const UserAccountCompletion: React.FC<Props> = (props) => {
  const { user, currentStep, onPrev, onUpdateProfile, loading, locale } = props;

  const intl = useIntl();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const { ToGetProvinces } = useHandelProvince();
  const provinceResponse = ToGetProvinces(1, -1);
  const [address, setAddress] = useState("");
  const provinces = getLocaleProvinces(locale, provinceResponse?.data?.rows);

  //   const onUpdate = async () => {
  //     try {
  //       const newUser: CreateUserProp = {
  //         provinceId: Number(selectedProvince?.id),
  //         address: address,
  //       };
  //       await onUpdateProfile(newUser);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   useEffect(() => {
  //     if (user && provinces) {
  //       const findedProvince = provinces.find(
  //         (item) => item.id === user?.provinceId
  //       );
  //       if (findedProvince) setSelectedProvince(findedProvince);
  //       else setSelectedProvince(provinces[0]);
  //       setAddress(user?.address || "");
  //     }
  //   }, [user, provinceResponse?.data]);

  return (
    <div>
      <div className="flex flex-col w-full h-[350px] justify-between">
        <div className=" space-y-10 text-center">
          <div className=" space-y-4">
            <Image src={TickMark} className="mx-auto mt-10" />
            <Typo.heading1 className="text-primary-600">
              <FormattedMessage
                id={TRANSLATION.ACCOUNT_COMPLETION_MESSAGE.id}
                defaultMessage={
                  TRANSLATION.ACCOUNT_COMPLETION_MESSAGE.defaultMessage
                }
              />
            </Typo.heading1>
          </div>
          <div className="flex items-end justify-center gap-x-6">
          <Button to={ROUTES.NEW_LISTING_ROUTE} theme="primary" size="medium" icon={<PlusIcon className="h-5" />} className="mt-4">
              <Typo.heading2 fontWeight="font-medium">
                <FormattedMessage id={TRANSLATION.ADD_NEW_LISTING.id} defaultMessage={TRANSLATION.ADD_NEW_LISTING.defaultMessage} />
              </Typo.heading2>
            </Button>
            <Button to={ROUTES.DASHBOARD_ROUTE} theme="secandory" size="medium" className="mt-4">
              <Typo.heading2 fontWeight="font-medium">
                <FormattedMessage id={TRANSLATION.PROFILE.id} defaultMessage={TRANSLATION.PROFILE.defaultMessage} />
              </Typo.heading2>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccountCompletion;
